exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-casino-games-js": () => import("./../../../src/pages/casino-games.js" /* webpackChunkName: "component---src-pages-casino-games-js" */),
  "component---src-pages-casino-reviews-all-slots-js": () => import("./../../../src/pages/casino-reviews/all-slots.js" /* webpackChunkName: "component---src-pages-casino-reviews-all-slots-js" */),
  "component---src-pages-casino-reviews-bovegas-js": () => import("./../../../src/pages/casino-reviews/bovegas.js" /* webpackChunkName: "component---src-pages-casino-reviews-bovegas-js" */),
  "component---src-pages-casino-reviews-casiyou-js": () => import("./../../../src/pages/casino-reviews/casiyou.js" /* webpackChunkName: "component---src-pages-casino-reviews-casiyou-js" */),
  "component---src-pages-casino-reviews-cherry-gold-js": () => import("./../../../src/pages/casino-reviews/cherry-gold.js" /* webpackChunkName: "component---src-pages-casino-reviews-cherry-gold-js" */),
  "component---src-pages-casino-reviews-exclusive-js": () => import("./../../../src/pages/casino-reviews/exclusive.js" /* webpackChunkName: "component---src-pages-casino-reviews-exclusive-js" */),
  "component---src-pages-casino-reviews-free-spin-js": () => import("./../../../src/pages/casino-reviews/free-spin.js" /* webpackChunkName: "component---src-pages-casino-reviews-free-spin-js" */),
  "component---src-pages-casino-reviews-gaming-club-js": () => import("./../../../src/pages/casino-reviews/gaming-club.js" /* webpackChunkName: "component---src-pages-casino-reviews-gaming-club-js" */),
  "component---src-pages-casino-reviews-jackpot-city-js": () => import("./../../../src/pages/casino-reviews/jackpot-city.js" /* webpackChunkName: "component---src-pages-casino-reviews-jackpot-city-js" */),
  "component---src-pages-casino-reviews-jackpot-town-js": () => import("./../../../src/pages/casino-reviews/jackpot-town.js" /* webpackChunkName: "component---src-pages-casino-reviews-jackpot-town-js" */),
  "component---src-pages-casino-reviews-js": () => import("./../../../src/pages/casino-reviews.js" /* webpackChunkName: "component---src-pages-casino-reviews-js" */),
  "component---src-pages-casino-reviews-lucky-nugget-js": () => import("./../../../src/pages/casino-reviews/lucky-nugget.js" /* webpackChunkName: "component---src-pages-casino-reviews-lucky-nugget-js" */),
  "component---src-pages-casino-reviews-platinum-play-js": () => import("./../../../src/pages/casino-reviews/platinum-play.js" /* webpackChunkName: "component---src-pages-casino-reviews-platinum-play-js" */),
  "component---src-pages-casino-reviews-prive-city-js": () => import("./../../../src/pages/casino-reviews/prive-city.js" /* webpackChunkName: "component---src-pages-casino-reviews-prive-city-js" */),
  "component---src-pages-casino-reviews-river-belle-js": () => import("./../../../src/pages/casino-reviews/river-belle.js" /* webpackChunkName: "component---src-pages-casino-reviews-river-belle-js" */),
  "component---src-pages-casino-reviews-royal-vegas-js": () => import("./../../../src/pages/casino-reviews/royal-vegas.js" /* webpackChunkName: "component---src-pages-casino-reviews-royal-vegas-js" */),
  "component---src-pages-casino-reviews-ruby-fortune-js": () => import("./../../../src/pages/casino-reviews/ruby-fortune.js" /* webpackChunkName: "component---src-pages-casino-reviews-ruby-fortune-js" */),
  "component---src-pages-casino-reviews-spin-ace-js": () => import("./../../../src/pages/casino-reviews/spin-ace.js" /* webpackChunkName: "component---src-pages-casino-reviews-spin-ace-js" */),
  "component---src-pages-casino-reviews-spin-js": () => import("./../../../src/pages/casino-reviews/spin.js" /* webpackChunkName: "component---src-pages-casino-reviews-spin-js" */),
  "component---src-pages-casino-reviews-two-up-js": () => import("./../../../src/pages/casino-reviews/two-up.js" /* webpackChunkName: "component---src-pages-casino-reviews-two-up-js" */),
  "component---src-pages-casiyou-new-fast-payout-casino-js": () => import("./../../../src/pages/casiyou-new-fast-payout-casino.js" /* webpackChunkName: "component---src-pages-casiyou-new-fast-payout-casino-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-earn-loyalty-rewards-at-casiyou-js": () => import("./../../../src/pages/earn-loyalty-rewards-at-casiyou.js" /* webpackChunkName: "component---src-pages-earn-loyalty-rewards-at-casiyou-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-casinos-js": () => import("./../../../src/pages/live-casinos.js" /* webpackChunkName: "component---src-pages-live-casinos-js" */),
  "component---src-pages-mobile-casinos-js": () => import("./../../../src/pages/mobile-casinos.js" /* webpackChunkName: "component---src-pages-mobile-casinos-js" */),
  "component---src-pages-online-baccarat-js": () => import("./../../../src/pages/online-baccarat.js" /* webpackChunkName: "component---src-pages-online-baccarat-js" */),
  "component---src-pages-online-blackjack-js": () => import("./../../../src/pages/online-blackjack.js" /* webpackChunkName: "component---src-pages-online-blackjack-js" */),
  "component---src-pages-online-craps-js": () => import("./../../../src/pages/online-craps.js" /* webpackChunkName: "component---src-pages-online-craps-js" */),
  "component---src-pages-online-roulette-js": () => import("./../../../src/pages/online-roulette.js" /* webpackChunkName: "component---src-pages-online-roulette-js" */),
  "component---src-pages-online-slots-js": () => import("./../../../src/pages/online-slots.js" /* webpackChunkName: "component---src-pages-online-slots-js" */),
  "component---src-pages-online-video-poker-js": () => import("./../../../src/pages/online-video-poker.js" /* webpackChunkName: "component---src-pages-online-video-poker-js" */),
  "component---src-pages-progressive-jackpots-js": () => import("./../../../src/pages/progressive-jackpots.js" /* webpackChunkName: "component---src-pages-progressive-jackpots-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-table-games-js": () => import("./../../../src/pages/table-games.js" /* webpackChunkName: "component---src-pages-table-games-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

